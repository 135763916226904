export const gridSize = {
  full: "12",
  half: "6",
}

export const bps = {
  sm: "(max-width: 768px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
}

export const colorsMap = {
  "#ffc64f": "yellow",
  "#ff744f": "orange",
  "#f5f4ef": "offWhite",
  "#90b4d5": "blue",
  "#ffffff": "white",
}

export const colorsList = ["yellow", "orange", "offWhite", "blue"]

export const defaultBgColor = "#f5f4ef"

export const resImgs = {
  xs: {
    sizes: ["xs", "md"],
    media: "(max-width: 639px)",
  },
  sm: {
    sizes: ["sm", "lg"],
    media: "(max-width: 768px)",
  },
  md: {
    sizes: ["md", "md2x"],
    media: "(max-width: 1023px)",
  },
  lg: {
    sizes: ["lg", "lg2x"],
    media: "(min-width: 1024px)",
  },
}
