import Cart from "@components/common/Cart"
import Footer from "@components/common/Footer"
import Header from "@components/common/Header"
import Helmet from "react-helmet"
import Loader from "@components/Loader"
import PropTypes from "prop-types"
import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import SvgSprite from "@components/common/SvgSprite"
import { colorsMap } from "@src/build/constants"
import useGeneralContext from "@components/common/hoc/useGeneralContext"
import loadable from "@loadable/component"

const CookieBanner = loadable(() => import("@components/CookieBanner"))

const variants = {
  pageInitial: {
    y: 0,
    opacity: 0,
  },
  pageEnter: {
    y: 0,
    opacity: 1,
  },
}

function Main({ children, pageContext, location }) {
  const { currentBgColor } = useGeneralContext()
  const paddedMain =
    pageContext.uri !== "/" && !pageContext.uri?.includes("stories")
  const bgColor = colorsMap[currentBgColor]
  const hideFooter = Object.keys(pageContext).length === 0

  return (
    <React.Fragment>
      <Helmet>
        <style type="text/css">{`
          @font-face {
            font-family: "Futura-Med";
            font-weight: regular;
            font-style: normal;
            font-display: swap;
            src: url("/fonts/Futura-Med.woff") format("woff");
          }

          @font-face {
            font-family: "HelveticaNeueLTPro-Lt";
            font-weight: 500;
            font-style: normal;
            font-display: swap;
            src: url("/fonts/HelveticaNeueLTProRoman.woff") format("woff");
          }
      `}</style>
      </Helmet>
      <SvgSprite />
      <Cart />
      <Loader />
      <Header />
      <main
        className={`transition-colors duration-500 ease-in-out bg-${bgColor}`}
      >
        <AnimatePresence initial={false} exitBeforeEnter>
          <motion.section
            key={location.pathname}
            variants={variants}
            initial="pageInitial"
            animate="pageEnter"
            exit="pageInitial"
            className={`grid grid-cols-12${
              paddedMain ? " pt-10h-screen" : " pt-0"
            }`}
            transition={{
              duration: 0.4,
              ease: "easeOut",
            }}
          >
            {children}
          </motion.section>
        </AnimatePresence>
      </main>
      {!hideFooter && (
        <AnimatePresence initial={false} exitBeforeEnter>
          <motion.footer
            key={`${location.pathname}1`}
            variants={variants}
            initial="pageInitial"
            animate="pageEnter"
            exit="pageInitial"
            className={`flex flex-col justify-between p-10 transition-colors duration-500 ease-in-out bg-${bgColor} text-bodyL`}
            transition={{
              duration: 0.4,
              ease: "easeOut",
            }}
          >
            <Footer />
          </motion.footer>
        </AnimatePresence>
      )}
      <CookieBanner />
    </React.Fragment>
  )
}

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  seo: PropTypes.object.isRequired,
}

Main.defaultProps = {
  seo: {},
}

export default Main
