import React from "react"
import { resImgs } from "@src/build/constants"

export const vimeoEmbedUrlParser = url => {
  if (url.includes("//player.")) return url

  const filtered = url.split("/").filter(item => item !== "")

  return `${filtered[0]}//player.${filtered[1]}/video/${filtered[2]}`
}

export const getAspectRatio = (width, height) => {
  // find the greatest common divisor
  function gcdHandler(a, b) {
    return b == 0 ? a : gcdHandler(b, a % b)
  }

  const gcd = gcdHandler(width, height)

  return [width / gcd, height / gcd]
}

export function srcSetHandler(list, size) {
  // remove unwanted image sizes
  const sizes = resImgs[size]?.sizes.map(s => list.filter(l => l.name === s)[0])

  if (!sizes) return null

  return `${sizes[0].sourceUrl}${sizes[1] ? `, ${sizes[1].sourceUrl} 2x` : ""}`
}

export function srcMediaHandler(size) {
  const media = resImgs[size]?.media

  if (!media) return null

  return media
}

export function imgSourcesHandler(sizes) {
  return sizes.map(size => {
    const srcSet = srcSetHandler(sizes, size.name)
    const media = srcMediaHandler(size.name)

    if (!srcSet || !media) return null

    return <source key={size.name} srcSet={srcSet} media={media} />
  })
}

export const getPrice = price =>
  Intl.NumberFormat(undefined, {
    currency: "GBP",
    minimumFractionDigits: 2,
    style: "currency",
  }).format(parseFloat(price ? price : 0))
