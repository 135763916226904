import { AnimatePresence, motion } from "framer-motion"

import CartItem from "@components/common/CartItem"
import React from "react"
import { getPrice } from "@src/helpers"
import { globalHistory } from "@reach/router"
import useGeneralContext from "@components/common/hoc/useGeneralContext"
import useStoreContext from "@components/common/hoc/useStoreContext"

function Cart() {
  const {
    store: {
      checkout: { lineItems, webUrl: checkoutUrl, totalPrice },
    },
  } = useStoreContext()
  const { activeCart, setActiveCart } = useGeneralContext()

  function handleCheckout() {
    window.open(checkoutUrl)
  }

  function scrollHandler() {
    if (activeCart) {
      setActiveCart(!activeCart)
    }
  }

  function clickHandler(e) {
    if (activeCart && e.currentTarget === e.target) {
      setActiveCart(!activeCart)
    }
  }

  React.useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", scrollHandler, { passive: true })
    }

    return () => {
      window.removeEventListener("scroll", scrollHandler, { passive: true })
    }
  }, [activeCart])

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setActiveCart(false)
    })
  }, [setActiveCart])

  return (
    <div
      onClick={clickHandler}
      className={`fixed top-0 left-0 w-full z-30 ${
        activeCart ? "h-full" : "h-0"
      }`}
    >
      <AnimatePresence exitBeforeEnter>
        {activeCart && (
          <motion.section
            key="cart-drawer"
            role="checkout"
            initial="hide"
            animate="show"
            exit="hide"
            variants={{
              show: {
                y: 0,
              },
              hide: {
                y: "-100%",
              },
            }}
            transition={{
              ease: "easeInOut",
              duration: 0.25,
            }}
            className={`absolute top-0 left-0 w-full bg-white text-bodyL md:text-header uppercase p-10 pb-50 md:pb-60`}
          >
            <div className="grid grid-cols-12">
              <div className="col-span-6 md:col-span-3">Cart</div>
              <div className="col-span-2 font-futura normal-case hidden md:block">
                Size
              </div>
              <div className="col-span-2 font-futura normal-case hidden md:block">
                Quantity
              </div>
              <div className="col-span-2 font-futura normal-case hidden md:block">
                Price
              </div>
              <div className="col-span-6 md:col-span-3 text-right">
                <button
                  type="button"
                  className="focus:outline-none uppercase"
                  onClick={() => setActiveCart(!activeCart)}
                  aria-label="Close Checkout"
                >
                  Close
                </button>
              </div>
            </div>
            <div className="grid grid-cols-12 mt-50">
              {lineItems.length === 0 && (
                <p className="col-span-12 font-futura normal-case">
                  The cart is empty.
                </p>
              )}
              {lineItems.map(item => (
                <CartItem key={item.id.toString()} item={item} />
              ))}
              {lineItems.length > 0 && (
                <div className="col-span-12 mt-40 md:col-span-5 md:col-start-8">
                  <button
                    onClick={handleCheckout}
                    disabled={lineItems.length === 0}
                    className="border-2 border-black text-header rounded-full uppercase pt-12 pb-6 px-12 mx-auto w-full md:w-auto leading-none focus:outline-none disabled:opacity-70 disabled:cursor-not-allowed md:mx-0"
                  >
                    Pay — {getPrice(totalPrice)}
                  </button>
                  <p className="normal-case font-futura mt-12 lg:inline-block lg:mt-0 lg:ml-12">
                    (Shipping not incl.)
                  </p>
                </div>
              )}
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Cart
