import { Link, graphql, useStaticQuery } from "gatsby"
import { colorsList, colorsMap } from "@src/build/constants"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import styled, { css } from "styled-components"

import CartReference from "@components/common/CartReference"
import PropTypes from "prop-types"
import React from "react"
import { globalHistory } from "@reach/router"
import hexToRgba from "hex-rgba"
import logoWhite from "@src/assets/logo-white.svg"
import useGeneralContext from "@components/common/hoc/useGeneralContext"
import useScrollPosition from "@react-hook/window-scroll"
import smoothScroll from "smoothscroll"

function Header() {
  const [logoAnimRange, setLogoAnimRange] = React.useState(0)
  const [windowH, setWindowH] = React.useState(1000)
  const [activeLinkColor, setActiveLinkColor] = React.useState("black")
  const { wpMenu } = useStaticQuery(query)
  const { nodes: items } = wpMenu.menuItems
  const { currentBgColor, isHome } = useGeneralContext()
  const bgColor = colorsMap[currentBgColor]
  const easing = [0.42, 0, 0.58, 1]
  // used to sync the header transition on scroll on homepage
  const { scrollYProgress } = useViewportScroll()
  // used to change nav color on scroll on homepage
  const scrollY = useScrollPosition(60)

  const y = useTransform(
    scrollYProgress,
    [0, 0.13],
    [0, -logoAnimRange],
    easing
  )
  const opacityExit = useTransform(scrollYProgress, [0, 0.1], [1, 0], easing)
  const opacityEnter = useTransform(
    scrollYProgress,
    [0.1, 0.15],
    [0, 1],
    easing
  )
  const padding = useTransform(
    scrollYProgress,
    [0, 0.13],
    [logoAnimRange, 10],
    easing
  )

  // facilitate logo hide on scroll (maintain correct aspect ratio)
  function resizeHandler() {
    if (typeof window !== undefined) {
      setLogoAnimRange(Math.round((window.innerWidth * 19.45) / 100) + 15)
      setWindowH(window.innerHeight)
    }
  }

  function scrollTopHandler() {
    return smoothScroll(0)
  }

  function scrollToStoriesHandler() {
    if (typeof window !== undefined) {
      const stories = document.querySelector("#stories")

      return smoothScroll(stories)
    }
  }

  React.useEffect(() => {
    if (isHome) {
      resizeHandler()

      window.addEventListener("resize", resizeHandler, { passive: true })

      return () => {
        window.removeEventListener("resize", resizeHandler, { passive: true })
      }
    }
  }, [resizeHandler])

  React.useEffect(() => {
    function activeLinkColorHandler() {
      const latest = colorsList.filter(col => col !== bgColor)

      return setActiveLinkColor(
        latest[Math.floor(Math.random() * latest.length)]
      )
    }

    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") activeLinkColorHandler()
    })
  }, [])

  if (!items) return null

  return (
    <StyledHeader
      bgColor={hexToRgba(currentBgColor)}
      isWhite={currentBgColor === "#ffffff"}
      isHome={isHome}
      className={`fixed top-0 left-0 w-full px-10 z-10 transition-color duration-200 ease-linear text-header ${
        isHome ? "overflow-hidden pb-10" : "py-10"
      } ${
        !isHome || (isHome && scrollY >= windowH) ? "text-black" : "text-white"
      }`}
    >
      <motion.nav style={{ paddingTop: isHome ? padding : 0 }}>
        {isHome && (
          <StyledLogoContainer
            style={{ y, x: "-50%" }}
            className="mb-10 absolute top-10 left-1/2"
          >
            <img src={logoWhite} />
          </StyledLogoContainer>
        )}
        <ul className="flex justify-start items-center space-x-2.5 uppercase mb-10">
          <li className="mr-auto relative">
            {isHome ? (
              <>
                <motion.button
                  type="button"
                  className="relative border-none bg-transparent focus:outline-none cursor-pointer leading-normal"
                  style={{ opacity: opacityExit, zIndex: opacityExit }}
                  onClick={scrollToStoriesHandler}
                >
                  MAGAZINE
                </motion.button>
                <motion.button
                  type="button"
                  className="absolute left-0 border-none bg-transparent focus:outline-none cursor-pointer leading-normal"
                  style={{ opacity: opacityEnter, zIndex: opacityEnter }}
                  onClick={scrollTopHandler}
                >
                  SUNDAY
                </motion.button>
              </>
            ) : (
              <Link to="/" className="relative" title="Go to Homepage">
                SUNDAY
              </Link>
            )}
          </li>
          {items.map((item, i) => {
            return (
              <li key={item.id}>
                <Link
                  to={item.url}
                  activeClassName={`text-${activeLinkColor}`}
                  partiallyActive={true}
                  title={`Go to ${item.label}`}
                >
                  {item.label}
                </Link>
              </li>
            )
          })}
          <CartReference />
        </ul>
      </motion.nav>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  ${({ bgColor, isHome, isWhite }) =>
    bgColor &&
    !isWhite &&
    !isHome &&
    css`
      &::before {
        content: "";
        display: block;
        width: 100%;
        box-shadow: 0px 10px 50px 45px ${bgColor};
        transition: box-shadow 0.15s ease-in-out;
      }
    `}
`

const StyledLogoContainer = styled(motion.div)`
  width: calc(100% - 16px);
`

Header.displayName = "Header"

Header.propTypes = {
  currentPage: PropTypes.string,
  absoluteNav: PropTypes.bool,
}

Header.defaultProps = {
  currentPage: "",
  absoluteNav: false,
}

const query = graphql`
  query {
    wpMenu(slug: { eq: "main-navigation" }) {
      menuItems {
        nodes {
          label
          id
          url
        }
      }
    }
  }
`

export default Header
