import { AnimatePresence, motion } from "framer-motion"

import React from "react"
import { colorsList } from "@src/build/constants"

export default function Loader() {
  const [bgColor, setBgColor] = React.useState("white")
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    setBgColor(colorsList[Math.floor(Math.random() * colorsList.length)])

    setTimeout(() => setLoaded(true), 500)
  }, [])

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {!loaded && (
        <motion.div
          key="loader"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.35,
            duration: 0.55,
          }}
          className={`fixed top-0 left-0 w-screen h-screen z-30 bg-${bgColor} transition-colors ease-out duration-75`}
        />
      )}
    </AnimatePresence>
  )
}
