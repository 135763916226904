import { AnimatePresence, motion } from "framer-motion"

import React from "react"
import useGeneralContext from "@components/common/hoc/useGeneralContext"
import useStoreContext from "@components/common/hoc/useStoreContext"

export default function CartReference() {
  const {
    currentBgColor,
    isHome,
    activeCart,
    setActiveCart,
  } = useGeneralContext()
  const {
    store: {
      checkout: { lineItems },
    },
  } = useStoreContext()

  return (
    <AnimatePresence exitBeforeEnter>
      {lineItems.length > 0 && (
        <motion.li
          key="cart-reference"
          initial="hide"
          animate="show"
          exit="hide"
          variants={{
            hide: {
              scale: 0,
              width: 0,
            },
            show: {
              scale: 1,
              width: "max-content",
            },
          }}
        >
          <button
            type="button"
            className="uppercase border-2 border-current rounded-full pt-6 px-9 focus:outline-none"
            style={{ transform: "translateY(-4px)" }}
            onClick={() => setActiveCart(!activeCart)}
            aria-label={`${activeCart ? "Open" : "Close"} Checkout`}
          >
            <span>{lineItems.length}</span>
          </button>
        </motion.li>
      )}
    </AnimatePresence>
  )
}
