import { Link } from "gatsby"
import React from "react"
import { getPrice } from "@src/helpers"
import useStoreContext from "@components/common/hoc/useStoreContext"

export default function LineItem({ item }) {
  const {
    removeLineItem,
    updateLineItem,
    store: { client, checkout },
  } = useStoreContext()
  const [inProgress, setInProgress] = React.useState(false)

  function inProgressHandler() {
    return setInProgress(!inProgress)
  }

  const selectedOptions = item?.variant?.selectedOptions
    ? item.variant.selectedOptions.map(option => {
        if (option.name === "Size") {
          return option.value
        }
      })[0]
    : null

  const clickHandler = action => {
    inProgressHandler()

    if (action === "add") {
      return updateLineItem(client, checkout.id, item.id, item.quantity + 1)
    } else if (action === "remove" && item.quantity > 1) {
      return updateLineItem(client, checkout.id, item.id, item.quantity - 1)
    }

    return removeLineItem(client, checkout.id, item.id)
  }

  React.useEffect(() => {
    if (inProgress) {
      inProgressHandler()
    }
  }, [item.quantity])

  return (
    <div
      className={`col-span-12 grid grid-cols-12${
        inProgress ? " opacity-75 pointer-none" : ""
      }`}
    >
      <div className="col-span-12 md:col-span-3">
        <Link to={`/shop/${item.variant.product.handle}/`}>{item.title}</Link>
      </div>
      <div className="col-span-6 md:col-span-2">
        {selectedOptions ? selectedOptions : "Standard"}
      </div>
      <div className="col-span-3 md:col-span-2">
        <button
          type="button"
          className="focus:outline-none"
          onClick={() => clickHandler("remove")}
        >
          -
        </button>{" "}
        <span>{item.quantity}</span>{" "}
        <button
          type="button"
          className="focus:outline-none"
          onClick={() => clickHandler("add")}
        >
          +
        </button>
      </div>
      <div className="col-span-3 md:col-span-2">
        {getPrice(item.variant.price)}
      </div>
    </div>
  )
}
