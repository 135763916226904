import "./src/styles/global.css"

import { GeneralContextProvider } from "@components/common/hoc/useGeneralContext"
import React from "react"
import { StoreContextProvider } from "@components/common/hoc/useStoreContext"

export const wrapRootElement = ({ element }) => (
  <GeneralContextProvider>
    <StoreContextProvider>{element}</StoreContextProvider>
  </GeneralContextProvider>
)

/**
  prevent Gatsby from scrolling page to top
  on exit transition before the animation ends
*/
const transitionDelay = 400

// export const onClientEntry = () => {
//   document.onreadystatechange = () => {
//     if (document.readyState === "complete") {
//       console.log("diocane")
//     }
//   }
// }

export const onInitialClientRender = () => {
  window.scrollTo(0, 0)
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  // if (location.pathname === "/") {
  //   return false
  // }

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  }

  return false
}
