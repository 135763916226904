import { Link, graphql, useStaticQuery } from "gatsby"

import React from "react"
import { colorsMap } from "@src/build/constants"
import logo from "@src/assets/logo.svg"
import useGeneralContext from "@components/common/hoc/useGeneralContext"

function Footer() {
  const { wpMenu } = useStaticQuery(query)
  const { nodes: items } = wpMenu.menuItems
  const { currentBgColor } = useGeneralContext()
  const bgColor = colorsMap[currentBgColor]
  // secondary navigation aria labels
  return (
    <>
      <nav className="flex uppercase space-x-2.5 md:mb-10">
        <ul className="flex-auto md:flex md:space-x-2.5">
          {items.map((item, i) => (
            <li
              key={item.id}
              // className={i === items.length - 1 ? "md:ml-auto-important " : ""}
            >
              {item.url.includes("http") ? (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener"
                  title={`Go to ${item.label}`}
                >
                  {item.label}
                </a>
              ) : (
                <Link to={item.url} title={`Go to ${item.label} Page`}>
                  {item.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <p className="mb-0 self-end">&copy;{new Date().getFullYear()} Sunday</p>
      </nav>
      <Link to="/" state={{ anchorTo: "stories" }}>
        <figure className="w-full">
          <img src={logo} />
        </figure>
      </Link>
    </>
  )
}

const query = graphql`
  query {
    wpMenu(slug: { eq: "footer-navigation" }) {
      menuItems {
        nodes {
          label
          id
          url
        }
      }
    }
  }
`

export default Footer
