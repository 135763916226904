import React from "react"
import { defaultBgColor } from "@src/build/constants"

// Create Context Object
const GeneralContext = React.createContext()

// Create a provider for components to consume and subscribe to changes
export const GeneralContextProvider = ({ children }) => {
  const [currentBgColor, setCurrentBgColor] = React.useState(defaultBgColor)
  const [isHome, setIsHome] = React.useState(false)
  const [activeCart, setActiveCart] = React.useState(false)

  return (
    <GeneralContext.Provider
      value={{
        currentBgColor,
        setCurrentBgColor,
        isHome,
        setIsHome,
        activeCart,
        setActiveCart,
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}

GeneralContextProvider.displayName = "GeneralContextProvider"

// export for methods
export default () => React.useContext(GeneralContext)
